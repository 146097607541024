<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="d-flex flex-column-fluid">
      <div class="container">
        <div class="d-flex flex-row">
         <SideBarProfile></SideBarProfile>
          <div class="flex-row-fluid ml-lg-8">
            <div class="card card-custom card-stretch">
              <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                  <h3 class="card-label font-weight-bolder text-dark">
                    {{$t('profile.user_change_password')}}
                  </h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">{{$t("profile.user_password_update")}}</span>
                </div>
                <div class="card-toolbar">
                  <button   :disabled="passwordValidation"  @click="UpdateProfile"  type="reset" class="btn btn-success mr-2">{{$t('general.save')}}</button>
                  <button @click="$router.push({name: 'profile.edit'})"  type="reset" class="btn btn-secondary">{{ $t("general.cancel") }}</button>
                </div>
              </div>
              <form class="form">
                <div class="card-body">
                  <img src="" alt=""  id="profile-image-holder">
                  <text-input
                      is-inline="true"
                      type="password"
                      :title="`${$t('profile.old_user_password')}`"
                      :model.sync="oldPassword"
                      :is-valid=" oldPassword != null && oldPassword.length !== 0 ? true : null"
                      :error=" oldPassword != null && oldPassword.length === 0 ? $t('general.this_field_cant_be_empty') : null"
                  ></text-input>
                  <text-input
                      is-inline="true"
                      type="password"
                      :title="`${$t('profile.now_user_password')}`"
                      :model.sync="password"
                      :is-valid=" password != null && password.length !== 0 ? true : null"
                      :error=" password != null && password.length === 0 ? $t('general.this_field_cant_be_empty') : null"
                  ></text-input>
                  <text-input
                      is-inline="true"
                      type="password"
                      :title="`${$t('profile.user_password_confirm')}`"
                      :model.sync="confirmPassword"
                      :is-valid=" confirmPassword != null && confirmPassword.length !== 0 && password === confirmPassword ? true : null"
                      :error="confirmPasswordError"
                  ></text-input>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardBox from "@/assets/components/DashboardBox";
import $ from 'jquery'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR,
  GET_ITEM_DETAIL_BY_ID,
  LOADING, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import TextInput from "@/assets/components/inputs/TextInput";
import {LANGUAGE} from "@/core/services/store/system-options.module";
import {USER} from "@/core/services/store/auth.module";
import FileInput from "@/assets/components/inputs/FileInput";
import SideBarProfile from "@/assets/components/profile/SideBarProfile";
export default {
  name: "ChangePassword",
  components: {
    TextInput,
    DashboardBox,
    FileInput,
    SideBarProfile
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
    }),
    passwordValidation(){
      if ((this.password == this.confirmPassword) && this.password != null && this.old_password != null){
        return false;
      } ;
    },

    confirmPasswordError(){
      if(this.confirmPassword != null && this.password != this.confirmPassword)
      {
        return this.$t('profile.confirm_password_must_be_equal_to_password');
      }
      else if(this.password != null && this.password < 8){
        return this.$t('profile.password_must_be_more_than_8');
      }
      else {
        return  null;
      }
    }
  },
  data() {
    return {
      languages: JSON.parse(this.systemLanguages),
      profileImage: null,
      oldPassword: null,
      password: null,
      confirmPassword: null,
    };
  },
  methods: {
    ...mapMutations({
    }),
    UpdateProfile(){

      let self = this;
        let payload = {
          url:'api/change-password',
          id: this.id,
          contents: {
            old_password: this.oldPassword,
            password: this.password,
            password_confirm : this.confirmPassword
          }
        }
        this.$store.dispatch(CREATE_ITEM, payload).then((result) => {
          if(result.status==true){
          self.sweetAlertSuccess(self.$t('general.successfully_updated'));
        }
      });
    },
  },
  watch:{
    oldPassword(){
      this.confirmPasswordError
    },
    password(){
      this.confirmPasswordError
    },
    confirmPassword(){
      this.confirmPasswordError
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("profile.user_change_password"), route: "profile.change-password" },
    ]);
  }
}
</script>
<style scoped>
</style>
